import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex';
import config from '../config/config';

let domain = 'particulier';

if (process.env.APP_TARGET === 'vve' || config.domain.includes('vve')) {
    domain = 'vve';
}

if (process.env.APP_TARGET === 'zakelijk' || config.domain.includes('zakelijk')) {
    domain = 'zakelijk';
}

const environment = {
    particulier: 'Particulier',
    zakelijk: 'Zakelijk',
    vve: 'VvE'
};

export const name = 'site';

export const types = {
    SET_SITE: 'SET_SITE'
};

export interface SiteState {
    site: string;
}

export const state = (): SiteState => ({
    site: domain
});

export interface Actions<S, R> extends ActionTree<S, R> {
    set(context: ActionContext<S, R>, site: string): void;
}

export const actions: Actions<SiteState, {}> = {
    set({ commit }, site: string): void {
        commit(types.SET_SITE, site);
    }
};

export const mutations: MutationTree<SiteState> = {
    [types.SET_SITE](newState, site: string): void {
        newState.site = site;
    }
};

export const getters: GetterTree<SiteState, {}> = {
    site: ({ site }): string => site,
    particulier: ({ site }): boolean => site !== 'vve' && site !== 'zakelijk',
    vve: ({ site }): boolean => site === 'vve',
    zakelijk: ({ site }): boolean => site === 'zakelijk',
    environment: ({ site }): string => environment[site]
};
