import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex';
import hash from 'object-hash';
import { ArticleEntry } from '~/types/Article';
import ContentFulArticle from '~/api/contentful/article';
import { MunicipalityType } from '~/types/Municipality';
import { TagType } from '~/types/Tag';

export const name = 'news';

export const types = {
    REPLACE: 'REPLACE',
    ADD: 'ADD'
};

/**
 * News articles are stored in a key-value manner in order to retrieve articles grouped by municipality / tags.
 * This function returns a hash based on municipality / tags that serves as a 'unique' key
 */
function generateHash(municipality: MunicipalityType, tags: TagType[]): string {
    let str: string = '';
    if (municipality) {
        str += municipality.fields.municipality;
    }
    if (tags && tags.length > 0) {
        const tagList = tags.map((tag): string | void => (tag.fields ? tag.fields.name : undefined));
        str += tagList.join(',');
    }
    str = hash(str);
    return str;
}

export interface NewsContext {
    municipality: MunicipalityType;
    tags: TagType[];
}

export interface NewsCollection {
    items: ArticleEntry[] | undefined;
}

export interface NewsState {
    news: object;
}

export const state = (): NewsState => ({
    news: {}
});

export interface Actions<S, R> extends ActionTree<S, R> {
    load(context: ActionContext<S, R>, newsContext: NewsContext): void;
}

export const actions: Actions<NewsState, {}> = {
    load({ commit }, { municipality, tags }): Promise<void | ArticleEntry> {
        const uniqueHash = generateHash(municipality, tags);

        return new ContentFulArticle().get(municipality, tags).then((entries): void => {
            const newState = {
                [uniqueHash]: entries.items as ArticleEntry[]
            };
            commit(types.ADD, { news: newState });
        });
    }
};

export const mutations: MutationTree<NewsState> = {
    [types.ADD](newState: NewsState, state2: NewsState): void {
        newState.news = { ...newState.news, ...(state2.news as NewsCollection) };
    }
};

export const getters: GetterTree<NewsState, {}> = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    news: ({ news }): any => (municipality: MunicipalityType, tags: TagType[]): ArticleEntry[] | undefined => {
        const newsKey = generateHash(municipality, tags);
        if (newsKey in news) {
            return news[newsKey] as ArticleEntry[];
        }
        return undefined;
    }
};
