import { Entry, EntryCollection } from 'contentful';
import { SlugFields } from '~/types/Slug';
import { ProductSolutionEntry } from '~/types/Product';
import { TagCollection } from '~/types/Tag';
import { HasSEOMetadata } from '~/interfaces/seoMetadata';
import { HasContentBlocks } from '~/interfaces/contentBlocks';

export interface ExperienceFields extends SlugFields, HasSEOMetadata, HasContentBlocks {
    name: string;
    title: string;
    description: string;
    image: string;
    username: string;
    buildingYear: string;
    appliedSolutions: ProductSolutionEntry[];
    experience: string;
    tags: TagCollection;
}

// eslint-disable-next-line no-shadow
export enum ExperienceType {
    /* eslint-disable no-unused-vars */
    ExperiencePage = 'pagina ervaring',
    ProductSolutionPage = 'pagina productoplossing'
}

export type ExperienceEntry = Entry<ExperienceFields>;
export type ExperienceCollection = EntryCollection<ExperienceEntry>;
