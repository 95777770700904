import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex';
import { InDeBuurtEntry, InDeBuurtType } from '~/types/InDeBuurt';
import { MunicipalityType } from '~/types/Municipality';
import ContentfulInDeBuurtItem from '~/api/contentful/indebuurt';

export const name = 'indebuurt';

export const types = {
    UPDATE: 'UPDATE',
    SETTINGS: 'SETTINGS'
};

export interface InDeBuurtState {
    eventsTotalPages: number;
    initiativesTotalPages: number;
    projectsTotalPages: number;
    combinedActionsTotalPages: number;
    events: InDeBuurtEntry[] | undefined;
    initiatives: InDeBuurtEntry[] | undefined;
    projects: InDeBuurtEntry[] | undefined;
    combinedActions: InDeBuurtEntry[] | undefined;
    combinedActionsTotalItems: number;
}

export const state = (): InDeBuurtState => ({
    eventsTotalPages: 0,
    initiativesTotalPages: 0,
    projectsTotalPages: 0,
    combinedActionsTotalPages: 0,
    events: undefined,
    initiatives: undefined,
    projects: undefined,
    combinedActions: undefined,
    combinedActionsTotalItems: 0
});

export interface Payload {
    paginationProperties: { pageNumber: number; perPage: number };
    municipality: MunicipalityType;
    type: InDeBuurtType;
}

export interface Actions<S, R> extends ActionTree<S, R> {
    load(context: ActionContext<S, R>, type: InDeBuurtType): void;
    // eslint-disable-next-line no-shadow
    loadpage(context: ActionContext<S, R>, Payload): void;
}

export const actions: Actions<InDeBuurtState, {}> = {
    load({ commit, rootGetters }, type: InDeBuurtType): Promise<void | InDeBuurtEntry> {
        return new ContentfulInDeBuurtItem().getAll(type, rootGetters['site/site']).then((entries): void => {
            commit(types.UPDATE, { type: type, entries: entries.items });
        });
    },

    loadpage({ commit, rootGetters }, payload: Payload): Promise<void | InDeBuurtEntry[]> {
        return new ContentfulInDeBuurtItem()
            .getByPage(
                payload.paginationProperties.pageNumber,
                payload.paginationProperties.perPage,
                payload.type === 'combinedAction' ? 'project,event' : payload.type,
                rootGetters['site/site'],
                payload.municipality
            )
            .then((entries): void => {
                const totalPages = Math.ceil(entries.total / payload.paginationProperties.perPage);
                commit(types.UPDATE, { entries: entries.items, type: payload.type });
                commit(types.SETTINGS, { type: payload.type, totalPages, totalItems: entries.total });
            });
    }
};

export const mutations: MutationTree<InDeBuurtState> = {
    [types.UPDATE](newState, { type, entries }: { type: InDeBuurtType; entries: InDeBuurtEntry[] }): void {
        newState[`${type}s`] = entries;
    },
    [types.SETTINGS](
        newState,
        { type, totalPages, totalItems }: { type: InDeBuurtType; totalPages: number; totalItems: number }
    ): void {
        newState[`${type}sTotalItems`] = totalItems;
        newState[`${type}sTotalPages`] = totalPages;
    }
};

export const getters: GetterTree<InDeBuurtState, {}> = {
    eventsTotalPages: ({ eventsTotalPages }): number => eventsTotalPages,
    initiativesTotalPages: ({ initiativesTotalPages }): number => initiativesTotalPages,
    projectsTotalPages: ({ projectsTotalPages }): number => projectsTotalPages,
    combinedActionsTotalPages: ({ combinedActionsTotalPages }): number => combinedActionsTotalPages,
    events: ({ events }): InDeBuurtEntry[] | undefined => events,
    initiatives: ({ initiatives }): InDeBuurtEntry[] | undefined => initiatives,
    projects: ({ projects }): InDeBuurtEntry[] | undefined => projects,
    combinedActions: ({ combinedActions }): InDeBuurtEntry[] | undefined => combinedActions,
    combinedActionsTotalItems: ({ combinedActionsTotalItems }): number => combinedActionsTotalItems
};
