import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex';
import Vue from 'vue';
import { set as setCookie, remove as removeCookie } from 'js-cookie';
import ContentfulMunicipality from '~/api/contentful/municipality';
import { MunicipalityFields, MunicipalityType } from '~/types/Municipality';
import { PageType } from '~/types/Page';

export const name = 'municipality';

export const cookieName = 'rel-vuex-municipality';

export const types = {
    UPDATE: 'UPDATE',
    SET_SLUG: 'SET_SLUG',
    CLEAR: 'CLEAR',
    ALL_SLUGS: 'ALL_SLUGS'
};

export interface MunicipalityState {
    municipality: MunicipalityType | null;
    slug: string | null;
    all: { [key: string]: string };
}

export const state = (): MunicipalityState => ({
    municipality: null,
    slug: null,
    all: {}
});

export interface Actions<S, R> extends ActionTree<S, R> {
    load(context: ActionContext<S, R>, slug: string): void;
}

export const actions: Actions<MunicipalityState, {}> = {
    async all(context: ActionContext<MunicipalityState, {}>): Promise<void | PageType> {
        if (!context.getters.loaded) {
            const entries = await new ContentfulMunicipality().getAllPages();
            return context.commit(types.ALL_SLUGS, entries.items);
        }
    },
    load({ commit }, slug: string): Promise<void | MunicipalityType> {
        return new ContentfulMunicipality().getBySlug(slug).then((entries): void => {
            commit(types.UPDATE, entries.items[0]);
        });
    },
    loadByApiId({ commit }, id: string): Promise<void | MunicipalityType> {
        return new ContentfulMunicipality().getByApiId(id).then((entries): void => {
            commit(types.UPDATE, entries.items[0]);
        });
    },
    loadByPageId({ commit }, id: string): Promise<void | MunicipalityType> {
        return new ContentfulMunicipality().getByPageId(id).then((entries): void => {
            commit(types.UPDATE, entries.items[0]);
        });
    },
    // eslint-disable-next-line no-shadow
    clear({ commit, state }): void {
        const { params } = this.$router.currentRoute;
        if (params.slug && state.slug === params.slug) {
            this.$router.push('/');
        }
        commit(types.CLEAR);
    },
    setSlug({ commit }, slug: string): void {
        commit(types.SET_SLUG, slug);
    }
};

export const mutations: MutationTree<MunicipalityState> = {
    [types.UPDATE](newState, municipality: MunicipalityType): void {
        newState.municipality = municipality;
    },

    [types.ALL_SLUGS](newState, pages: PageType[]): void {
        Vue.set(
            newState,
            'all',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            pages.reduce((acc: any, entry: PageType): any => {
                if (entry && entry.fields && entry.fields.slug) {
                    acc[entry.fields.slug] = entry?.fields?.municipality?.fields?.municipality;
                }
                return acc;
            }, {})
        );
    },

    [types.SET_SLUG](newState, slug: string | undefined): void {
        if (newState.municipality) {
            if (!newState.municipality.fields) {
                Vue.set(newState.municipality, 'fields', {});
            }
            Vue.set(newState.municipality.fields, 'slug', slug);

            // persist the slug to keep the navigation pointing to the municipality found via zipcode
            Vue.set(newState, 'slug', slug);
            // this cookie is used to redirect the user using a server-side script when they visit the home-page.
            setCookie(cookieName, slug, { expires: 30 });
        }
    },

    [types.CLEAR](newState): void {
        newState.municipality = null;
        newState.slug = null;
        removeCookie(cookieName);
        // Also ensure location state is cleared.
        // @ts-ignore
        this.dispatch('location/clear');
    }
};

export const getters: GetterTree<MunicipalityState, {}> = {
    municipality: ({ municipality }): MunicipalityType | null => municipality,
    fields: ({ municipality }): MunicipalityFields | null =>
        municipality && municipality.fields ? municipality.fields : null,
    municipalitySlug: ({ slug }): string | null => slug,
    all: ({ all }): { [key: string]: string } => all,
    loaded: ({ all }): boolean => !!Object.keys(all).length
};
