import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex';
import SupplierRELAPI from '~/api/micro-rel/supplier';

import { PaginationProperties, PaginationSettings } from '~/interfaces/pagination';
import { FilterDefinition } from '~/types/FilterDefinition';
import { SupplierCollection } from '~/types/Supplier';
import { MunicipalityType } from '~/types/Municipality';
import { Location } from '~/store/location';

export const name = 'suppliers';

export const types = {
    SET_SUPPLIERS: 'SET_SUPPLIERS',
    PAGINATION: 'PAGINATION'
};
export interface SuppliersState {
    totalPages: number;
    totalEntries: number;
    suppliers: SupplierCollection | null;
}

export const state = (): SuppliersState => ({
    totalPages: 1,
    totalEntries: 0,
    suppliers: null
});

export interface Actions<S, R> extends ActionTree<S, R> {
    getAllSuppliers(content: ActionContext<S, R>): void;

    getPaginatedSuppliers(
        context: ActionContext<S, R>,
        { pagination, filters }: { pagination: PaginationProperties; filters?: FilterDefinition[] }
    ): void;
}

export const actions: Actions<SuppliersState, {}> = {
    getAllSuppliers({ commit, rootGetters }): Promise<void | SupplierCollection> {
        return new SupplierRELAPI().getAll(rootGetters['site/site']).then((entries): void => {
            commit(types.SET_SUPPLIERS, entries);
        });
    },

    getPaginatedSuppliers(
        { commit, rootGetters },
        {
            pagination,
            filters = [],
            userLocation
        }: { pagination: PaginationProperties; filters: FilterDefinition[]; userLocation: Location }
    ): Promise<void | SupplierCollection> {
        const site = rootGetters['site/site'];
        const municipality: MunicipalityType | undefined = rootGetters['municipality/municipality'] || undefined;

        return new SupplierRELAPI()
            .getFilteredByPage(site, filters, pagination, userLocation, municipality)
            .then((entries): void => {
                if ('total' in entries) {
                    const totalPages = Math.ceil(entries.total / pagination.perPage);
                    commit(types.SET_SUPPLIERS, entries);
                    commit(types.PAGINATION, { totalPages: totalPages, totalEntries: entries.total });
                }
            });
    }
};

export const mutations: MutationTree<SuppliersState> = {
    [types.SET_SUPPLIERS](newState, suppliers: SupplierCollection): void {
        newState.suppliers = suppliers;
    },

    [types.PAGINATION](newState, pageSettings: PaginationSettings): void {
        newState.totalPages = pageSettings.totalPages;
        newState.totalEntries = pageSettings.totalEntries;
    }
};

export const getters: GetterTree<SuppliersState, {}> = {
    totalPages: ({ totalPages }): number => totalPages,
    totalEntries: ({ totalEntries }): number => totalEntries,
    suppliers: ({ suppliers }): SupplierCollection | null => suppliers
};
