import { Entry } from '~/node_modules/contentful';
import { ExternalPageType, PageType } from '~/types/Page';

// eslint-disable-next-line no-shadow
export enum TypeButton {
    /* eslint-disable no-unused-vars */
    Primary = 'Primair',
    Secondary = 'Secundair',
    Tertiary = 'Tertiair'
}
export interface KnopFields {
    name: string;
    icon: string;
    txt: string;
    destination: ExternalPageType | PageType;
    openAsIframe: boolean;
    typeButton: string;
    showOn?: string;
    showUntil?: string;
}

export type KnopType = Entry<KnopFields>;
