import hash from 'object-hash';
import { Entry } from 'contentful';
import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex';

import { PaginationProperties, PaginationSettings } from '~/interfaces/pagination';

import { ExperienceCollection, ExperienceEntry } from '~/types/Experience';
import ExperienceAPI from '~/api/contentful/experience';
import { ProductSolutionEntry } from '~/types/Product';
import { FilterDefinition } from '~/types/FilterDefinition';

export const name = 'experiences';

export const types = {
    SET_EXPERIENCES: 'SET_EXPERIENCES',
    SET_RELATED_EXPERIENCES: 'SET_RELATED_EXPERIENCES',
    PAGINATION: 'PAGINATION'
};

export interface ExperiencesState {
    totalPages: number;
    totalEntries: number;
    experiences: ExperienceCollection | null;
    relatedExperiences: object;
}

export const state = (): ExperiencesState => ({
    totalPages: 1,
    totalEntries: 0,
    experiences: null,
    relatedExperiences: {}
});

/**
 * Related experiences are stored in a key-value manner in order to retrieve experiences grouped by solution / pag ID.
 * This function returns a hash based on solutions + page ID that serves as a 'unique' key
 */
function generateHash(solutions: ProductSolutionEntry[], pageId: string): string {
    let str: string = '';
    if (solutions) {
        const idList = solutions.map((solution): string | void => (solution.sys ? solution.sys.id : undefined));
        str += idList.join(',');
    }
    if (pageId) {
        str += pageId;
    }
    str = hash(str);
    return str;
}

export interface Actions<S, R> extends ActionTree<S, R> {
    getAllExperiences(content: ActionContext<S, R>): void;
    getPaginatedExperiences(
        context: ActionContext<S, R>,
        { pagination, filters }: { pagination: PaginationProperties; filters?: FilterDefinition[] }
    ): void;
    getExperiencesBySolutions(context: ActionContext<S, R>, { solutions, pageId }): void;
}

export const actions: Actions<ExperiencesState, {}> = {
    getAllExperiences({ commit }): Promise<void | ExperienceCollection> {
        return new ExperienceAPI().getAll().then((entries): void => {
            commit(types.SET_EXPERIENCES, entries);
        });
    },

    getPaginatedExperiences(
        { commit },
        { pagination, filters }: { pagination: PaginationProperties; filters: FilterDefinition[] }
    ): Promise<void | ExperienceCollection> {
        if (filters && filters.length) {
            return new ExperienceAPI().getFilteredByPage(filters, pagination).then((entries): void => {
                const totalPages = Math.ceil(entries.total / pagination.perPage);
                commit(types.SET_EXPERIENCES, entries);
                commit(types.PAGINATION, { totalPages: totalPages, totalEntries: entries.total });
            });
        } else {
            return new ExperienceAPI().getByPage(pagination.pageNumber, pagination.perPage).then((entries): void => {
                const totalPages = Math.ceil(entries.total / pagination.perPage);
                commit(types.SET_EXPERIENCES, entries);
                commit(types.PAGINATION, { totalPages: totalPages, totalEntries: entries.total });
            });
        }
    },

    getExperiencesBySolutions({ commit }, { solutions, pageId }): Promise<void | ExperienceCollection> {
        const key = generateHash(solutions, pageId);

        return new ExperienceAPI().getByProductSolutions(solutions, pageId).then((entries): void => {
            const newState = {
                [key]: entries
            };
            commit(types.SET_RELATED_EXPERIENCES, newState);
        });
    }
};

export const mutations: MutationTree<ExperiencesState> = {
    [types.SET_EXPERIENCES](newState, experiences: ExperienceCollection): void {
        newState.experiences = experiences;
    },
    [types.SET_RELATED_EXPERIENCES](newState, experiences: ExperienceCollection): void {
        newState.relatedExperiences = { ...newState.relatedExperiences, ...experiences };
    },
    [types.PAGINATION](newState, pageSettings: PaginationSettings): void {
        newState.totalPages = pageSettings.totalPages;
        newState.totalEntries = pageSettings.totalEntries;
    }
};

export const getters: GetterTree<ExperiencesState, {}> = {
    totalPages: ({ totalPages }): number => totalPages,
    totalEntries: ({ totalEntries }): number => totalEntries,
    experiences: ({ experiences }): ExperienceCollection | null => experiences,
    experienceItems: ({ experiences }): Entry<ExperienceEntry>[] | null =>
        experiences && experiences.items ? experiences.items : null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    relatedExperiences: ({ relatedExperiences }): any => (
        solutions: ProductSolutionEntry[],
        pageId: string
    ): ExperienceCollection | undefined => {
        const key = generateHash(solutions, pageId);
        if (relatedExperiences && key in relatedExperiences) {
            return relatedExperiences[key] as ExperienceCollection;
        }
        return undefined;
    }
};
