import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex';
import axios from 'axios';
import config from '~/config/config';

export const name = 'location';

export const types = {
    UPDATE_LOCATION: 'UPDATE_LOCATION',
    UPDATE_ZIPCODE: 'UPDATE_ZIPCODE',
    CLEAR_ALL: 'CLEAR_ALL'
};

export interface LocationDetail {
    id: string;
    name: string;
}

export interface GeoLocation {
    latitude: number;
    longitunde: number; // XXX: N.B.: typo in API
}

export interface Location {
    postcode: string;
    street: string;
    town: string;
    neighbourhood: LocationDetail;
    district: LocationDetail;
    municipality: LocationDetail;
    geolocation: GeoLocation;
}

export interface LocationState {
    zipcode: string | null;
    location: Location | null;
}

export const state = (): LocationState => ({
    zipcode: null,
    location: null
});

export interface Actions<S, R> extends ActionTree<S, R> {
    select(context: ActionContext<S, R>, id: number): void;
}

export const actions: Actions<LocationState, {}> = {
    select({ commit }, id: number): void {
        commit(types.UPDATE_LOCATION, id);
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async fetch({ commit }, zipcode: string): Promise<any | never> {
        commit(types.UPDATE_ZIPCODE, zipcode);

        const response = await axios.get(`${config.api.baseURL}${config.api.zipcodeURL}/${zipcode}`);

        commit(types.UPDATE_LOCATION, response.data);
        return response.data;
    },

    clear({ commit }): void {
        commit(types.CLEAR_ALL);
    }
};

export const mutations: MutationTree<LocationState> = {
    [types.UPDATE_ZIPCODE](newState, zipcode: string): void {
        newState.zipcode = zipcode;
    },
    [types.UPDATE_LOCATION](newState, location: Location): void {
        newState.location = location;
    },
    [types.CLEAR_ALL](newState): void {
        newState.location = null;
        newState.zipcode = null;
    }
};

export const getters: GetterTree<LocationState, {}> = {
    location: ({ location }): Location | null => location,
    zipcode: ({ zipcode }): string | null => zipcode
};
