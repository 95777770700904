import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex';
import { AlertEntry, AlertCollection } from '~/types/Alert';
import { PageProperties } from '~/types/Page';
import AlertApi from '~/api/contentful/alert';

export const name = 'alert';

export const types = {
    UPDATE: 'UPDATE',
    MAP: 'MAP'
};

export interface AlertState {
    alerts: AlertEntry[];
    alertsMap: { [key: string]: AlertEntry[] };
}

export const state = (): AlertState => ({
    alerts: [],
    alertsMap: {}
});

const development = process.env.NODE_ENV === 'development';

export interface Actions<S, R> extends ActionTree<S, R> {
    load(context: ActionContext<S, R>): void;
    loadalerts(context: ActionContext<S, R>, pageProperties: PageProperties): void;
    clear(context: ActionContext<S, R>): void;
}

/* eslint-disable no-shadow */
export const actions: Actions<AlertState, {}> = {
    load({ commit }): Promise<void | AlertEntry> {
        return new AlertApi().getAll().then((entries): void => {
            commit(types.UPDATE, entries.items[0]);
        });
    },

    loadalerts({ state, commit, rootGetters }, pageProperties: PageProperties): Promise<void | AlertCollection> | AlertEntry[] {
        const { pageId, municipalityId, zipcode } = pageProperties;
        const isPublic: boolean = rootGetters['site/particulier'];
        const key = [pageId, municipalityId, zipcode, isPublic].join('-');

        if (state.alertsMap[key] && state.alertsMap[key].length) {
            if (development) {
                // eslint-disable-next-line no-console
                console.log(`alert/loadalerts: from cache ${key}`);
            }
            commit(types.UPDATE, state.alertsMap[key]);
            const alerts: AlertEntry[] = state.alertsMap[key];
            return alerts;
        }

        return new AlertApi().getByVisibility(isPublic, pageId, municipalityId, zipcode).then((entries): void => {
            if (development) {
                // eslint-disable-next-line no-console
                console.log(`alert/loadalerts: Contentful API ${key}`);
            }
            commit(types.UPDATE, entries);
            commit(types.MAP, key);
        });
    },

    clear({ commit }): void {
        commit(types.UPDATE, []);
    }
};

export const mutations: MutationTree<AlertState> = {
    // eslint-disable-next-line no-shadow
    [types.UPDATE](state, alerts: AlertEntry[]): void {
        state.alerts = alerts;
    },

    [types.MAP](state, key: string): void {
        if (state.alertsMap[key] && state.alertsMap[key].length) {
            if (development) {
                // eslint-disable-next-line no-console
                console.warn(`alerts for ${key} already in store`);
            }
            return;
        }
        state.alertsMap = { ...state.alertsMap, [key]: state.alerts };
    }
};

export const getters: GetterTree<AlertState, {}> = {
    alerts: ({ alerts }): AlertEntry[] | undefined => {
        const copy = [...alerts];
        return copy.sort((a, b): number => {
            if (a.fields && b.fields) {
                // Higher prio goes first
                if (a.fields.priority > b.fields.priority) {
                    return -1;
                }
                if (a.fields.priority < b.fields.priority) {
                    return 1;
                }
            }
            // a must be equal to b
            return 0;
        });
    }
};
