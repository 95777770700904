import { MutationTree, GetterTree } from 'vuex';
import Vue from 'vue';
import { FilterDefinition, FilterOptionDefinition } from '~/types/FilterDefinition';
import {
    ActiveFilterState,
    ActiveFilterActions,
    findFilterIndex,
    findOptionForFilterIndex
} from '~/helpers/store/filters';

export const name = 'FinancialBenefitFilters';

export const state = (): ActiveFilterState => ({
    activeFilters: []
});

export const actions: ActiveFilterActions<ActiveFilterState, {}> = {
    // eslint-disable-next-line no-shadow
    addActiveOptionToFilter({ state, commit }, { filter, option }): void {
        const index = findFilterIndex(state, filter);
        if (index === -1) {
            filter.options = [option];
            commit('pushFilter', { filter });
        } else if (findOptionForFilterIndex(state, index, option) === -1) {
            commit('pushOption', { option, index });
        }
    },
    // eslint-disable-next-line no-shadow
    removeActiveOptionFromFilter({ commit, state }, { filter, option }): void {
        const filterIndex = findFilterIndex(state, filter);
        if (filterIndex !== -1) {
            const optionIndex = findOptionForFilterIndex(state, filterIndex, option);
            if (optionIndex !== -1) {
                commit('removeOptionByIndex', { filterIndex, optionIndex });
            }
        }
    },
    clearAllActiveFilters({ commit }): void {
        commit('clearAll');
    }
};

export const mutations: MutationTree<ActiveFilterState> = {
    pushFilter({ activeFilters }, { filter }): void {
        activeFilters.push(filter);
    },
    pushOption({ activeFilters }, { option, index }): void {
        activeFilters[index].options.push(option);
    },
    removeOptionByIndex({ activeFilters }, { filterIndex, optionIndex }): void {
        activeFilters[filterIndex].options.splice(optionIndex, 1);
        if (!activeFilters[filterIndex].options.length) {
            activeFilters.splice(filterIndex, 1);
        }
    },
    // eslint-disable-next-line no-shadow
    clearAllActiveFilters(state): void {
        Vue.set(state, 'activeFilters', []);
    }
};

export const getters: GetterTree<ActiveFilterState, {}> = {
    activeFilters: ({ activeFilters }): FilterDefinition[] => activeFilters,
    // eslint-disable-next-line no-shadow,@typescript-eslint/no-explicit-any
    activeOptionsForFilter: (state): any => (filter: FilterDefinition): FilterOptionDefinition[] => {
        return state.activeFilters[findFilterIndex(state, filter)].options;
    }
};
