import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex';

import { PaginationProperties, PaginationSettings } from '~/interfaces/pagination';

import { FinancialBenefitEntry } from '~/types/FinancialBenefit';
import FinancialBenefitApi from '~/api/contentful/financial-benefit';
import { FilterDefinition } from '~/types/FilterDefinition';

export const name = 'financialbenefits';

export const types = {
    UPDATE: 'UPDATE',
    SETTINGS: 'SETTINGS'
};

export interface FinancialBenefitsState {
    totalPages: number;
    totalEntries: number;
    financialBenefits: FinancialBenefitEntry[] | undefined;
}

export const state = (): FinancialBenefitsState => ({
    totalPages: 0,
    totalEntries: 0,
    financialBenefits: undefined
});

export interface Actions<S, R> extends ActionTree<S, R> {
    load(context: ActionContext<S, R>): void;
    loadpage(
        context: ActionContext<S, R>,
        {
            pagination,
            filters,
            municipality
        }: { pagination: PaginationProperties; filters?: FilterDefinition[]; municipality }
    ): void;
}

export const actions: Actions<FinancialBenefitsState, {}> = {
    load({ commit, rootGetters }): Promise<void | FinancialBenefitEntry> {
        const site = rootGetters['site/site'];
        return new FinancialBenefitApi().getAll(site).then((entries): void => {
            commit(types.UPDATE, entries.items);
        });
    },

    loadpage(
        { commit, rootGetters },
        {
            pagination,
            filters,
            municipality
        }: { pagination: PaginationProperties; filters: FilterDefinition[]; municipality }
    ): Promise<void | FinancialBenefitEntry[]> {
        const site = rootGetters['site/site'];

        if (filters && filters.length) {
            return new FinancialBenefitApi()
                .getFilteredByPage(filters, pagination, site, municipality)
                .then((entries): void => {
                    const totalPages = Math.ceil(entries.total / pagination.perPage);
                    commit(types.UPDATE, entries.items);
                    commit(types.SETTINGS, { totalPages: totalPages, totalEntries: entries.total });
                });
        } else {
            return new FinancialBenefitApi()
                .getByPage(pagination.pageNumber, pagination.perPage, site, municipality)
                .then((entries): void => {
                    const totalPages = Math.ceil(entries.total / pagination.perPage);
                    commit(types.UPDATE, entries.items);
                    commit(types.SETTINGS, { totalPages: totalPages, totalEntries: entries.total });
                });
        }
    }
};

export const mutations: MutationTree<FinancialBenefitsState> = {
    [types.UPDATE](newState, financialBenefits: FinancialBenefitEntry[]): void {
        newState.financialBenefits = financialBenefits;
    },
    [types.SETTINGS](newState, pageSettings: PaginationSettings): void {
        newState.totalPages = pageSettings.totalPages;
        newState.totalEntries = pageSettings.totalEntries;
    }
};

export const getters: GetterTree<FinancialBenefitsState, {}> = {
    totalPages: ({ totalPages }): number => totalPages,
    totalEntries: ({ totalEntries }): number => totalEntries,
    financialBenefits: ({ financialBenefits }): FinancialBenefitEntry[] | undefined => financialBenefits
};
