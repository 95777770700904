import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex';
import { FooterFields, FooterType } from '~/types/Footer';
import FooterApi from '~/api/contentful/footer';
export const name = 'footer';

export const types = {
    UPDATE: 'UPDATE'
};

export interface FooterState {
    footer: FooterType | undefined;
}

export const state = (): FooterState => ({
    footer: undefined
});

export interface Actions<S, R> extends ActionTree<S, R> {
    load(context: ActionContext<S, R>): void;
}

export const actions: Actions<FooterState, {}> = {
    load({ commit, rootGetters }): Promise<void | FooterType> {
        return new FooterApi().getAll(rootGetters['site/site']).then((entries): void => {
            commit(types.UPDATE, entries.items[0]);
        });
    }
};

export const mutations: MutationTree<FooterState> = {
    [types.UPDATE](newState, footer: FooterType): void {
        newState.footer = footer;
    }
};

export const getters: GetterTree<FooterState, {}> = {
    footer: ({ footer }): FooterType | undefined => footer,
    footerFields: ({ footer }): FooterFields | undefined => footer ? footer.fields : undefined
};
