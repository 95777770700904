import { MutationTree, GetterTree } from 'vuex';
import Vue from 'vue';
import { FilterDefinition, FilterOptionDefinition } from '~/types/FilterDefinition';
import {
    ActiveFilterState,
    findFilterIndex,
    findOptionForFilterIndex,
    ActiveSupplierFilterActions
} from '~/helpers/store/filters';

export const name = 'SupplierFilters';

export const state = (): ActiveFilterState => ({
    activeFilters: [
        {
            id: 'show-within-radius',
            name: 'Show within radius',
            options: [
                {
                    key: 'showWithinRadius-1',
                    name: 'Toon bedrijven binnen een radius',
                    value: 30
                }
            ],
            field: 'showWithinRadius',
            operator: '[in]',
            type: 'slider',
            showButton: false
        },
        {
            id: 'show-active-in-area-only',
            name: 'Show active in area only',
            options: [
                {
                    key: 'showActiveInAreaOnly-1',
                    name: 'Toon alleen vakspecialisten waarbij u binnen het werkgebied valt',
                    value: true
                }
            ],
            field: 'showActiveInAreaOnly',
            operator: '[in]',
            type: 'showActiveInAreaOnly',
            showButton: false
        }
    ]
});

export const actions: ActiveSupplierFilterActions<ActiveFilterState, {}> = {
    // eslint-disable-next-line no-shadow
    addActiveOptionToFilter({ state, commit }, { filter, option }): void {
        const index = findFilterIndex(state, filter);
        if (index === -1) {
            filter.options = [option];
            commit('pushFilter', { filter });
        } else if (findOptionForFilterIndex(state, index, option) === -1) {
            commit('pushOption', { option, index });
        }
    },
    // eslint-disable-next-line no-shadow
    removeActiveOptionFromFilter({ commit, state }, { filter, option }): void {
        const filterIndex = findFilterIndex(state, filter);
        if (filterIndex !== -1) {
            const optionIndex = findOptionForFilterIndex(state, filterIndex, option);
            if (optionIndex !== -1) {
                commit('removeOptionByIndex', { filterIndex, optionIndex });
            }
        }
    },
    // eslint-disable-next-line no-shadow
    updateActiveOptionToFilter({ commit, state }, { filter, option }): void {
        const filterIndex = findFilterIndex(state, filter);
        if (filterIndex !== -1) {
            const optionIndex = findOptionForFilterIndex(state, filterIndex, option);
            if (optionIndex !== -1) {
                commit('updateOptionByIndex', { filterIndex, filter, optionIndex });
            }
        }
    },
    clearAllActiveFilters({ commit }): void {
        commit('clearAllActiveFilters');
    }
};

export const mutations: MutationTree<ActiveFilterState> = {
    pushFilter({ activeFilters }, { filter }): void {
        activeFilters.push(filter);
    },
    pushOption({ activeFilters }, { option, index }): void {
        activeFilters[index].options.push(option);
    },
    removeOptionByIndex({ activeFilters }, { filterIndex, optionIndex }): void {
        activeFilters[filterIndex].options.splice(optionIndex, 1);
        if (!activeFilters[filterIndex].options.length) {
            activeFilters.splice(filterIndex, 1);
        }
    },
    updateOptionByIndex({ activeFilters }, { filterIndex, filter, optionIndex }): void {
        activeFilters[filterIndex].options.splice(optionIndex, 1);
        if (!activeFilters[filterIndex].options.length) {
            activeFilters.splice(filterIndex, 1);
            activeFilters.push(filter);
        }
    },
    // eslint-disable-next-line no-shadow
    clearAllActiveFilters(state): void {
        Vue.set(state, 'activeFilters', []);
    }
};

export const getters: GetterTree<ActiveFilterState, {}> = {
    activeFilters: ({ activeFilters }): FilterDefinition[] => activeFilters,
    // eslint-disable-next-line no-shadow,@typescript-eslint/no-explicit-any
    activeOptionsForFilter: (state): any => (filter: FilterDefinition): FilterOptionDefinition[] => {
        return state.activeFilters[findFilterIndex(state, filter)].options;
    }
};
