import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex';

export const types = {
    UPDATE: 'UPDATE'
};

interface NavigationState {
    showSecondaryLinks: boolean;
}

interface Actions<S, R> extends ActionTree<S, R> {
    setShowSecondaryLinks(context: ActionContext<S, R>, showSecondaryLinks: boolean): void;
}

export const state = (): NavigationState => ({
    showSecondaryLinks: false
});

export const actions: Actions<NavigationState, {}> = {
    setShowSecondaryLinks({ commit }, showSecondaryLinks: boolean): void {
        commit(types.UPDATE, showSecondaryLinks);
    }
};

export const mutations: MutationTree<NavigationState> = {
    [types.UPDATE](newState, showSecondaryLinks: boolean): void {
        newState.showSecondaryLinks = showSecondaryLinks;
    }
};

export const getters: GetterTree<NavigationState, {}> = {
    showSecondaryLinks: ({ showSecondaryLinks }): boolean => showSecondaryLinks
};
