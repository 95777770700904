import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex';

export const name = 'modals';

export const types = {
    ADD: 'ADD',
    REMOVE: 'REMOVE',
    IFRAME: 'IFRAME'
};

export interface ModalsState {
    openModals: string[];
    currentLinkSrc: string | undefined;
}

export const state = (): ModalsState => ({
    openModals: [],
    currentLinkSrc: undefined
});

function removeIfAlreadyAdded(newState: ModalsState, id: string): ModalsState {
    // avoid adding the same modal more than once.
    const index = newState.openModals.findIndex((openModalId): boolean => openModalId === id);
    if (index !== -1) {
        newState.openModals.splice(index, 1);
    }
    return newState;
}

export interface Actions<S, R> extends ActionTree<S, R> {
    add(context: ActionContext<S, R>, id: string): void;
    remove(context: ActionContext<S, R>, id: string ): void;
    setExternalUrl(context: ActionContext<S, R>, url: string ): void;
}

export const actions: Actions<ModalsState, {}> = {
    add({ commit }, id: string): void {
        commit(types.ADD, id);
    },

    remove({ commit }, id: string): void {
        commit(types.REMOVE, id);
    },

    setExternalUrl({ commit }, url: string): void {
        commit(types.IFRAME, url);
    }
};

export const mutations: MutationTree<ModalsState> = {
    [types.IFRAME](newState, src: string | undefined): void {
        newState.currentLinkSrc = src;
    },
    [types.ADD](newState, id: string): void {
        newState = removeIfAlreadyAdded(newState, id);
        newState.openModals.push(id);
    },
    [types.REMOVE](newState, id: string): void {
        newState = removeIfAlreadyAdded(newState, id);
    }
};

export const getters: GetterTree<ModalsState, {}> = {
    openModals: ({ openModals }): string[] => openModals,
    currentLinkSrc: ({ currentLinkSrc }): string | undefined => currentLinkSrc
};
